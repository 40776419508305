import { Children, cloneElement, useRef } from 'react'

export const VerticalSlidesContainer = ({
  children,
}: {
  children: JSX.Element | JSX.Element[]
}) => {
  const containerRef = useRef<HTMLDivElement>(null)
  return (
    <div ref={containerRef}>
      {Children.map(children, (element, index) => {
        return cloneElement(element, {
          containerRef: containerRef,
          index,
          style: { transform: `translateY(${(index + 1) * 2}rem)` },
        })
      })}
    </div>
  )
}
