'use client'

import type { AnimationOptions } from '@lib/animation/applyAnimation'
import applyAnimation from '@lib/animation/applyAnimation'
import type { ReactNode } from 'react'
import { useEffect, useRef } from 'react'

/**
 *
 * @param animationOptions - a configuration of animation options
 * @param children - one or more React nodes to apply the animation to
 * @param className - additional classes to set on the wrapper of the child nodes
 * @param loop - optional boolean indicating whether or not the animation should loop (default false)
 * @returns - void
 */
export default function AnimatedContainer({
  animationOptions,
  children,
  className,
  loop = false,
}: {
  children: ReactNode | ReactNode[]
  className?: string
} & AnimationOptions) {
  const element = useRef(null)

  useEffect(() => {
    applyAnimation({
      animationOptions,
      element: element.current,
      loop,
    })
  }, [])

  return (
    <div className={className} ref={element}>
      {children}
    </div>
  )
}
