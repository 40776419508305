const fadeInPreset = ({
  delay = 0,
  duration,
}: {
  delay?: number
  duration?: number
}) => ({
  delay,
  duration,
  easing: 'easeInQuad',
  opacity: [0, 1],
})

const fadeInUpPreset = ({
  delay = 0,
  duration,
}: {
  delay?: number
  duration?: number
}) => ({
  delay,
  duration,
  easing: 'easeOutQuart',
  opacity: [0, 1],
  translateY: [1000, 0],
})

const fadeOutUpPreset = ({
  delay = 0,
  duration,
}: {
  delay?: number
  duration?: number
}) => ({
  delay,
  duration,
  easing: 'easeOutQuart',
  opacity: [1, 0],
  translateY: [0, -500],
})

const fadeOutPreset = ({
  delay = 0,
  duration,
}: {
  delay?: number
  duration?: number
}) => ({
  delay,
  duration,
  easing: 'easeInQuad',
  opacity: [1, 0],
})

const animationPresets = {
  fadeIn: fadeInPreset,
  fadeInUp: fadeInUpPreset,
  fadeOut: fadeOutPreset,
  fadeOutUp: fadeOutUpPreset,
}

export default animationPresets
