import { Text } from '@components/primitives/Text'
import { classMerge } from '@components/utilities/classMerge'

export default function FilterPill({
  className,
  id,
  onClick,
  selected,
  value,
}: {
  className?: string
  id?: string
  onClick: (value: string) => void
  selected: boolean
  value: string
}) {
  return (
    <div
      className={classMerge(
        'flex justify-center rounded-full border-2 px-4 py-1 transition-transform hover:cursor-pointer',
        {
          'bg-primary500': selected,
          'border-neutral200': !selected,
          'border-primary500': selected,
        },
        className,
      )}
      id={id}
      onClick={() => onClick(value)}
    >
      <Text
        className={classMerge(
          'whitespace-nowrap',
          selected ? 'text-white' : 'text-neutral600',
        )}
        styleName='p-small'
        value={value}
      />
    </div>
  )
}
