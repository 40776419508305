import { FullSizedCloudflareImage } from '@components/primitives/Image/Cloudflare'
import { Text } from '@components/primitives/Text'
import FaqItem from './FaqItem'

export default function FaqSection() {
  return (
    <div className='mt-24 flex lg:gap-12'>
      <div className='w-full lg:w-2/3'>
        <Text
          className='sm:text-center md:text-left'
          element='h2'
          styleName='h2'
          value='Frequently Asked Questions'
        />
        <FaqItem
          description="Almost all clinical trials will cover the cost of the 'trial drug' — so no insurance is required for this. For trials where this trial drug is given alongside an already-approved medication, there may be a cost (which your insurance would normally cover)."
          heading='Do I need insurance to participate in a trial?'
        />
        <FaqItem
          description='Many of the teams running clinical trials will cover the cost of transportation to-and-from their care center.'
          heading='Is there any support for travel costs?'
        />
        <FaqItem
          description="This depends on the specific study. If you're worried about receiving a placebo, you can actively filter out these trials using our search."
          heading='Will I know what medication I am taking?'
        />
        <FaqItem
          description="Some trials will only require a single visit, while others will continue until your disease returns. It's fairly common for a  trial to last somewhere between 1 and 6 months."
          heading='How long do clinical trials last?'
        />
        <FaqItem
          description="All of the trials listed on Power have been formally registered with the US Food and Drug Administration. Beyond this, some trials on Power have been formally 'verified' if the team behind the trial has completed an additional level of verification with our team."
          heading='Do you verify all the trials on your website?'
        />
        <FaqItem
          description="Sadly, this response time can take anywhere from 6 hours to 2 weeks. We're working hard to speed up how quickly you hear back — in general, verified trials respond to patients within a few days."
          heading='How quickly will I hear back from a clinical trial?'
        />
      </div>
      <div className='hidden h-min overflow-hidden rounded-xl object-cover lg:block'>
        <FullSizedCloudflareImage
          alt='Doctor with patient'
          imageId='08556d4c-b201-4bc3-360d-d1cbde34c000'
          style={{ height: 'auto', width: 350 }}
        />
      </div>
    </div>
  )
}
