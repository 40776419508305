'use client'

import { AnimatedAccordion } from '@components/primitives/AnimatedAccordion'
import { Icon } from '@components/primitives/Icon'
import { Text } from '@components/primitives/Text'
import { classMerge } from '@components/utilities/classMerge'
import { faChevronDown } from '@fortawesome/pro-regular-svg-icons/faChevronDown'
import { useState } from 'react'

export default function FaqItem({
  description,
  heading,
}: {
  description: string
  heading: string
}) {
  const [isExpanded, setIsExpanded] = useState(false)

  return (
    <div className='border-b border-neutral200 py-4'>
      <div
        className='flex cursor-pointer items-center gap-3'
        onClick={() => setIsExpanded(!isExpanded)}
      >
        <Icon
          className={classMerge('transform transition-all duration-500', {
            'rotate-180': isExpanded,
          })}
          icon={faChevronDown}
        />
        <Text value={heading} />
      </div>
      <AnimatedAccordion isExpanded={isExpanded}>
        <Text
          className='mt-2 text-neutral600'
          styleName='p-small'
          value={description}
        />
      </AnimatedAccordion>
    </div>
  )
}
