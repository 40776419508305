import type { AnimationOptions } from '@lib/animation/applyAnimation'
import applyAnimation from '@lib/animation/applyAnimation'
import omit from 'lodash/omit'
import { useEffect, useRef } from 'react'

export function AnimatedDiv(
  props: AnimationOptions & React.HTMLProps<HTMLDivElement>,
) {
  const element = useRef(null)

  useEffect(() => {
    applyAnimation({
      animationOptions: props.animationOptions,
      element: element.current,
    })
  }, [])

  return <div ref={element} {...omit(props, 'animationOptions')} />
}
