import { AnimatedDiv } from '@components/primitives/AnimatedContainer/AnimatedDiv'
import { Text } from '@components/primitives/Text'
import animationPresets from '@lib/animation/presets'
import type { BackgroundColor } from '@lib/colors/colors'
import { useState } from 'react'
import { ConditionTopic, type ConditionCardData } from '../consts'
import { ConditionSectionCard } from './ConditionSectionCard'
import HorizontalButtonCarousel from './HorizontalCarousel'

interface ConditionCardSectionProps {
  cards: ConditionCardData[]
  heading: string
  subheading: string
}

const conditionCardBackgroundColors: BackgroundColor[] = [
  'bg-green200',
  'bg-blue200',
  'bg-warm200',
  'bg-turquoise200',
  'bg-success200',
  'bg-primary200',
]

/** The section immediately below the home page's hero. **/
export function ConditionCardSection({
  cards,
  heading,
  subheading,
}: ConditionCardSectionProps) {
  const [activeFilter, setActiveFilter] = useState<string | undefined>(
    ConditionTopic.Psychiatry,
  )

  const activeCards = cards.filter(
    (card) =>
      card.topic === activeFilter || activeFilter === ConditionTopic.All,
  )

  return (
    <div className='mx-auto mb-24 flex w-full max-w-screen-2xl flex-row items-center justify-start gap-4'>
      <AnimatedDiv
        animationOptions={animationPresets.fadeIn({
          delay: 1200,
          duration: 400,
        })}
        className='grid w-full grid-cols-1 gap-4 opacity-0'
      >
        <Text
          className='sm:text-center md:text-left'
          element='h3'
          styleName='h3'
          value={heading}
        />
        <Text
          className='sm:text-center md:text-left'
          element='p'
          value={subheading}
        />

        <HorizontalButtonCarousel
          activeFilter={activeFilter}
          filters={Object.values(ConditionTopic)}
          onFilterChange={setActiveFilter}
        />

        <div className='flex flex-col items-center gap-4 md:grid md:grid-cols-2 lg:grid-cols-3'>
          {activeCards.map((card, index) => (
            <ConditionSectionCard
              backgroundColor={
                conditionCardBackgroundColors[
                  index % conditionCardBackgroundColors.length
                ] ?? 'bg-green200'
              }
              condition={card.condition}
              description={card.description}
              key={card.condition}
            />
          ))}
        </div>
      </AnimatedDiv>
    </div>
  )
}
