import { Slide } from '@components/primitives/VerticalSlides/Slide'
import { VerticalSlidesContainer } from '@components/primitives/VerticalSlides/VerticalSlidesContainer'
import { card1, card2, card3 } from '@modules/images/pages'

export default function StepsSection() {
  return (
    <div className='mb-24 mt-12 flex w-full flex-grow flex-col gap-16 py-12 text-primary50 lg:mx-auto'>
      <VerticalSlidesContainer>
        <Slide
          imageId={card1}
          margin={64}
          subtitle='Quickly filter trials to see a short list of the best matches for your current health'
          title='Search across all clinical trials'
        />
        <Slide
          imageId={card2}
          margin={64}
          subtitle='Power has the best information available on every trial page'
          title='Find the most promising result'
        />
        <Slide
          imageId={card3}
          margin={64}
          subtitle='Streamline the process and connect directly with researchers'
          title='Applying only takes a minute'
        />
      </VerticalSlidesContainer>
    </div>
  )
}
