import { FullSizedCloudflareImage } from '@components/primitives/Image/Cloudflare'
import { Text } from '@components/primitives/Text'
import { classMerge } from '@components/utilities/classMerge'
import type { RefObject } from 'react'
import { useCallback, useEffect, useRef, useState } from 'react'

type SlideProps = {
  containerRef?: RefObject<HTMLDivElement>
  imageId?: string
  index?: number
  margin: number
  subtitle?: string
  title: string
}

export const Slide = ({
  containerRef,
  imageId,
  index = 0,
  margin,
  subtitle,
  title,
}: SlideProps) => {
  const ref = useRef<HTMLDivElement>(null)
  const [style, setStyle] = useState({
    top: `${margin + 30}px`,
    transform: `translateY(${margin * index}px)`,
    transformOrigin: 'center top',
  })
  const initPos = useRef<number>()

  const getPosition = useCallback(() => {
    if (!ref.current || !containerRef?.current) return
    const { height, top } = ref.current.getBoundingClientRect()
    const parent = containerRef.current.getBoundingClientRect()

    const windowOffset =
      window.pageYOffset || document.documentElement.scrollTop
    const offsetTop = ref.current.offsetTop

    // element is glued to the top
    if (top === margin * index + margin) {
      if (!initPos.current) {
        initPos.current =
          parent.top +
          windowOffset -
          document.documentElement.clientTop +
          index * height +
          margin
      }

      const scale = Math.min(
        1,
        (height - (offsetTop - initPos.current) * 0.05) / height,
      )
      setStyle((style) => ({
        ...style,
        transform: `translateY(${margin * index}px) scale(${scale})`,
      }))
    }
  }, [containerRef, index, margin])

  useEffect(() => {
    window.addEventListener('scroll', getPosition)
    return () => {
      window.removeEventListener('scroll', getPosition)
    }
  }, [getPosition])

  return (
    <div className={classMerge('sticky', '-top-1')} ref={ref} style={style}>
      <div
        className={classMerge(
          'mb-2 flex min-h-[400px] flex-col rounded-lg shadow-lg md:flex-row',
        )}
      >
        <div className='flex flex-1 flex-col justify-center rounded-t-lg bg-neutral50 p-4 md:rounded-l-lg md:rounded-tr-none lg:p-8'>
          <div className='mb-6 flex size-14 items-center justify-center rounded-lg bg-neutral200 lg:mb-8 lg:size-14'>
            <Text
              className='text-left text-black'
              styleName='h3'
              value={index + 1}
            />
          </div>
          <Text
            className='text-left text-neutral900'
            styleName='h3'
            value={title}
          />
          {subtitle && (
            <Text
              className='mt-2 text-left leading-normal text-neutral600 lg:mt-4 lg:leading-[1.8]'
              styleName='p'
              value={subtitle}
            />
          )}
        </div>
        <div className='flex flex-1 rounded-b-lg md:rounded-r-lg md:rounded-bl-none'>
          {imageId && (
            <div className='relative overflow-hidden rounded-b-lg md:rounded-r-lg md:rounded-bl-none'>
              <FullSizedCloudflareImage
                alt={title}
                imageId={imageId}
                style={{
                  height: 420,
                  objectFit: 'cover',
                  objectPosition: 'left top',
                }}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  )
}
