import applyAnimation, {
  type AnimationOptions,
} from '@lib/animation/applyAnimation'
import omit from 'lodash/omit'
import { useEffect, useRef } from 'react'
import { Text, type TextProps } from '.'

export function AnimatedText(
  textProps: Omit<TextProps, 'forwardedRef'> & AnimationOptions,
) {
  const element = useRef(null)

  useEffect(() => {
    applyAnimation({
      animationOptions: textProps.animationOptions,
      element: element.current,
    })
  }, [])

  return (
    <Text forwardedRef={element} {...omit(textProps, 'animationOptions')} />
  )
}
