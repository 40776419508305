import type { Testimonial } from '@components/app/HomePage/TestimonialSection/consts'
import { Text } from '@components/primitives/Text'
import { classMerge } from '@components/utilities/classMerge'
import { FullSizedCloudflareImage } from '../Image/Cloudflare'

type TestimonialCardProps = Testimonial & {
  alt: string
  clamp?: number
}

export const TestimonialCard = ({
  alt,
  clamp,
  condition,
  imageId,
  quote,
  subtitle,
}: TestimonialCardProps) => {
  function getLineClampClassName(clamp?: number) {
    if (!clamp) {
      return null
    }

    return `line-clamp-${clamp}`
  }

  return (
    <div className='grid h-[18.75rem] w-[20rem] overflow-hidden rounded-xl border border-neutral200 md:w-[37.5rem] md:grid-cols-5'>
      <div className='hidden overflow-hidden rounded-l-xl md:col-span-2 md:block'>
        <FullSizedCloudflareImage
          alt={alt}
          imageId={imageId}
          style={{
            height: 300,
            objectFit: 'cover',
          }}
        />
      </div>
      <div className='flex flex-col justify-center rounded-r-xl bg-neutral50 p-8 md:col-span-3 md:block'>
        <Text
          className={classMerge(
            'mb-4 text-neutral900',
            getLineClampClassName(clamp),
          )}
          element='p'
          styleName='p-small'
          value={quote}
        />
        <Text
          className='font-semibold'
          element='p'
          styleName='p-tiny'
          value={`${condition} Patient`}
        />
        <Text
          className='text-neutral600'
          element='p'
          styleName='p-tiny'
          value={subtitle}
        />
      </div>
    </div>
  )
}
