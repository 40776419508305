import { TestimonialCard } from '@components/primitives/TestimonialCard'
import { type Testimonial } from './consts'

const TESTIMONIAL_CARD_LINE_CLAMP = 6

export default function TestimonialCarousel({
  speed,
  testimonials,
}: {
  speed: 'slow' | 'fast'
  testimonials: Testimonial[]
}) {
  const animationBySpeed = {
    fast: 'animate-[horizontalRollLeft_150s_linear_infinite]',
    slow: 'animate-[horizontalRollLeft_180s_linear_infinite]',
  }
  const animationClassName = `flex gap-4 ${animationBySpeed[speed]}`

  return (
    <div className='relative flex gap-4 overflow-hidden'>
      <div className='absolute left-0 z-10 h-full w-[4rem] bg-gradient-to-r from-white to-transparent md:w-[8rem]' />
      <div className='absolute right-0 z-10 h-full w-[4rem] bg-gradient-to-l from-white to-transparent md:w-[8rem]' />
      <div className={animationClassName}>
        {testimonials.map((testimonial, index) => (
          <TestimonialCard
            {...testimonial}
            alt={`patient-testimonial-${index}`}
            clamp={TESTIMONIAL_CARD_LINE_CLAMP}
            key={`patient-testimonial-${index}-1`}
          />
        ))}
      </div>
      <div className={animationClassName}>
        {testimonials.map((testimonial, index) => (
          <TestimonialCard
            {...testimonial}
            alt={`patient-testimonial-${index}`}
            clamp={TESTIMONIAL_CARD_LINE_CLAMP}
            key={`patient-testimonial-${index}-2`}
          />
        ))}
      </div>
    </div>
  )
}
