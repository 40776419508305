import DiscoverySection from '@components/app/DiscoverySection'
import { ConditionCardSection } from '@components/app/HomePage/ConditionCardSection'
import type { ConditionCardData } from '@components/app/HomePage/consts'
import { CONDITION_TOPICS } from '@components/app/HomePage/consts'
import ExplorePanel from '@components/app/HomePage/ExplorePanel'
import FaqSection from '@components/app/HomePage/FaqSection'
import { HomeHero } from '@components/app/HomePage/HomeHero'
import ResultsSummary from '@components/app/HomePage/ResultsSummary'
import StepsSection from '@components/app/HomePage/StepsSection'
import TestimonialSection from '@components/app/HomePage/TestimonialSection'
import { NavBar } from '@components/app/NavBar'
import { GradientFade } from '@components/primitives/Gradient/gradientVerticalFade'
import { getParamsProvidedLayoutWithLayoutProps } from '@components/primitives/Layout/getPatientLayouts'
import OpenGraphCard from '@components/primitives/Layout/HeaderComponents/OpenGraphCard'
import type { PageWidth } from '@components/primitives/Layout/HeaderComponents/ResponsivePageMargin'
import Routes from '@lib/routes'
import absoluteUrl from '@lib/routes/helpers/absoluteUrl'
import { spacing } from '@lib/spacing'
import { getNearestMaxNumber } from '@lib/utilities/getNearestMaxNumber'
import { ONE_DAY_IN_SECONDS } from '@lib/utilities/time'
import getCachedDirectoryTopicTrialCount, {
  getCachedDirectoryTopicTrialCountPastMonth,
} from '@modules/directoryTopics/helpers/cache/getCachedDirectoryTopicTrialCount'
import { DIRECTORY_TOPIC_TRIAL_COUNT_MAX } from '@modules/trials/helpers/getTrialCountByDirectoryTopic'
import type { GetStaticPropsContext, GetStaticPropsResult } from 'next'

type HomepageProps = {
  conditionTopics: ConditionCardData[]
}

const DIRECTORY_CARD_TRIAL_COUNT_MIN = 100
const DIRECTORY_CARD_TRIAL_COUNT_STEP = 10

export async function getStaticProps(
  _context: GetStaticPropsContext,
): Promise<GetStaticPropsResult<HomepageProps>> {
  async function extendWithTrialCountData(topic: ConditionCardData) {
    const [cachedTrialCount, cachedTrialCountPastMonth] = await Promise.all([
      getCachedDirectoryTopicTrialCount(topic.slug),
      getCachedDirectoryTopicTrialCountPastMonth(topic.slug),
    ])

    if (!cachedTrialCount) {
      return topic
    }

    const trialCountToDisplay = `${getNearestMaxNumber(
      cachedTrialCount.trialCount,
      DIRECTORY_TOPIC_TRIAL_COUNT_MAX,
      DIRECTORY_CARD_TRIAL_COUNT_MIN,
      DIRECTORY_CARD_TRIAL_COUNT_STEP,
    )} trials`

    const trialCountPastMonthToDisplay = cachedTrialCountPastMonth?.trialCount
      ? `${cachedTrialCountPastMonth.trialCount} added last month`
      : null

    const description = trialCountPastMonthToDisplay
      ? `${trialCountToDisplay} · ${trialCountPastMonthToDisplay}`
      : trialCountToDisplay

    return {
      ...topic,
      description,
    }
  }

  const conditionTopics = await Promise.all(
    CONDITION_TOPICS.map(extendWithTrialCountData),
  )

  return {
    props: {
      conditionTopics,
    },
    revalidate: ONE_DAY_IN_SECONDS,
  }
}

const Homepage = ({ conditionTopics }: HomepageProps) => {
  return (
    <>
      <OpenGraphCard
        description={description}
        title='Find Promising New Treatments Through Clinical Trials'
        url={absoluteUrl(Routes.home)}
      />
      <NavBar
        maxWidth={HOMEPAGE_CONTENT_WIDTH}
        showForProviders={true}
        transparentBackground={true}
      />
      <GradientFade backgroundGradient='organicPeach' grainy height={1000} />
      <div className='grid grid-cols-1 gap-4'>
        <HomeHero
          heading={'Where patients find promising new treatments'}
          subheading={
            'Browse clinical trials by condition, location, and drug type.'
          }
        />
        <ResultsSummary />
        <DiscoverySection />
        <ConditionCardSection
          cards={conditionTopics}
          heading='Browse a broad selection of new treatments'
          subheading='Choose from over 30,000 active clinical trials, with new additions every month.'
        />
        <TestimonialSection />
        <StepsSection />
        <FaqSection />
        <ExplorePanel />
      </div>
    </>
  )
}

const description =
  'Discover cutting-edge therapies by enrolling in clinical trials. Power helps patients everywhere take control of their care.'

const HOMEPAGE_CONTENT_WIDTH = 'max-w-screen-xl' as PageWidth

Homepage.getLayout = getParamsProvidedLayoutWithLayoutProps({
  contentWidth: HOMEPAGE_CONTENT_WIDTH,
  description,
  loadMaze: true,
  spacing: spacing.none,
  title: 'Find Promising New Treatments Through Clinical Trials',
})

Homepage.contentWidth = HOMEPAGE_CONTENT_WIDTH

export default Homepage
