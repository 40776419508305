import { Icon } from '@components/primitives/Icon'
import { classMerge } from '@components/utilities/classMerge'
import { faChevronLeft } from '@fortawesome/pro-solid-svg-icons/faChevronLeft'
import { faChevronRight } from '@fortawesome/pro-solid-svg-icons/faChevronRight'
import { useEffect, useState } from 'react'
import FilterPill from './FilterPill'

export default function HorizontalButtonCarousel({
  activeFilter,
  filters,
  onFilterChange,
  type = 'condition-topic',
}: {
  activeFilter?: string
  filters: string[]
  onFilterChange: (activeFilter?: string) => void
  type?: string
}) {
  const [horizontalTranslateIndex, setHorizontalTranslateIndex] = useState(0)
  const [horizontalTranslateAmount, setHorizontalTranslateAmount] = useState(0)
  const [hideRightTranslateIcon, setHideRightTranslateIcon] = useState(false)
  const [lastTranslateDirection, setLastTranslateDirection] = useState<
    'left' | 'right'
  >()

  const translateIconWrapperClassNames =
    'absolute z-20 flex h-full items-center hover:cursor-pointer'
  const translateIconClassNames =
    'flex size-10 items-center justify-center rounded-full border border-neutral200 bg-white'
  const gradientFadeClassNames =
    'pointer-events-none absolute z-10 h-full w-[4rem] from-white to-transparent md:w-[8rem]'

  // Hide the right-arrow icon once the right-most filter is in view
  useEffect(() => {
    const rightMostElement = document.getElementById(
      `${type}-filter-${filters.length - 1}`,
    )

    if (!rightMostElement) {
      return
    }

    const rightMostRect = rightMostElement.getBoundingClientRect()

    const shouldHideRightIcon =
      rightMostRect.right < window.innerWidth &&
      lastTranslateDirection === 'right'

    setHideRightTranslateIcon(shouldHideRightIcon)
  }, [horizontalTranslateIndex])

  function handleHorizontalTranslate(direction: 'left' | 'right') {
    // Ensure we don't attempt to translate past the first or last filter
    if (
      (direction === 'right' &&
        horizontalTranslateIndex >= filters.length - 1) ||
      (direction === 'left' && horizontalTranslateIndex <= 0)
    ) {
      return
    }

    // When moving to the right, we use the width of the current filter (i.e. left-most filter on-screen)
    // When moving left, we want to shift by the width of the first filter off-screen
    const referenceElement = document.getElementById(
      direction === 'right'
        ? `${type}-filter-${horizontalTranslateIndex}`
        : `${type}-filter-${horizontalTranslateIndex - 1}`,
    )

    if (!referenceElement) {
      return
    }

    const { offsetWidth } = referenceElement

    const newHorizontalTranslateAmount =
      horizontalTranslateAmount +
      (direction === 'left' ? offsetWidth : -offsetWidth)

    // Maintain a reference of the element at the left-most position on-screen
    setHorizontalTranslateIndex(
      direction === 'right'
        ? horizontalTranslateIndex + 1
        : horizontalTranslateIndex - 1,
    )

    updateFiltersHorizontalPositioning(newHorizontalTranslateAmount)
    setLastTranslateDirection(direction)
    // Maintain a running total of the amount we've translated the filters
    setHorizontalTranslateAmount(newHorizontalTranslateAmount)
  }

  function updateFiltersHorizontalPositioning(horizontalPosition: number) {
    for (let i = 0; i < filters.length; i++) {
      const element = document.getElementById(`${type}-filter-${i}`)
      if (!element) {
        continue
      }
      element.style.setProperty(
        'transform',
        `translateX(${horizontalPosition}px)`,
      )
    }
  }

  return (
    <div className='relative w-full'>
      <div
        className={classMerge('left-0', translateIconWrapperClassNames, {
          hidden: horizontalTranslateIndex === 0,
        })}
        onClick={() => handleHorizontalTranslate('left')}
      >
        <div className={translateIconClassNames}>
          <Icon icon={faChevronLeft} />
        </div>
      </div>
      <div
        className={classMerge('right-0', translateIconWrapperClassNames, {
          hidden:
            horizontalTranslateIndex === filters.length - 1 ||
            hideRightTranslateIcon,
        })}
        onClick={() => handleHorizontalTranslate('right')}
      >
        <div className={translateIconClassNames}>
          <Icon icon={faChevronRight} />
        </div>
      </div>
      <div className='relative overflow-hidden'>
        <div
          className={classMerge(
            'left-0 bg-gradient-to-r',
            gradientFadeClassNames,
            {
              hidden: horizontalTranslateIndex === 0,
            },
          )}
        />
        <div
          className={classMerge(
            'right-0 bg-gradient-to-l',
            gradientFadeClassNames,
            {
              hidden:
                horizontalTranslateIndex === filters.length - 1 ||
                hideRightTranslateIcon,
            },
          )}
        />
        <div className='flex gap-1'>
          {filters.map((filterValue, index) => {
            const pillId = `${type}-filter-${index}`
            return (
              <FilterPill
                id={pillId}
                key={pillId}
                onClick={onFilterChange}
                selected={activeFilter === filterValue}
                value={filterValue}
              />
            )
          })}
        </div>
      </div>
    </div>
  )
}
