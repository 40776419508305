import AnimatedContainer from '@components/primitives/AnimatedContainer/index.client'
import { Icon } from '@components/primitives/Icon'
import { Text } from '@components/primitives/Text'
import type { IconDefinition } from '@fortawesome/fontawesome-common-types'
import { faCity } from '@fortawesome/pro-regular-svg-icons/faCity'
import { faHospital } from '@fortawesome/pro-regular-svg-icons/faHospital'
import { faMicroscope } from '@fortawesome/pro-regular-svg-icons/faMicroscope'
import { faStethoscope } from '@fortawesome/pro-regular-svg-icons/faStethoscope'
import animationPresets from '@lib/animation/presets'

export default function ResultsSummary() {
  const Result = ({ copy, icon }: { copy: string; icon: IconDefinition }) => (
    <div className='mx-5 my-2 flex flex-wrap items-center justify-center align-baseline'>
      <Icon className='mr-2 text-black' icon={icon} />
      <Text className='font-semibold leading-5' value={copy} />
    </div>
  )

  return (
    <AnimatedContainer
      animationOptions={animationPresets.fadeIn({
        delay: 1200,
        duration: 400,
      })}
      className='mx-auto mb-0 flex flex-wrap items-center justify-center opacity-0 lg:mb-20'
    >
      <Result copy='31k+ Clinical Trials' icon={faMicroscope} />
      <Result copy='100k Researchers' icon={faHospital} />
      <Result copy='10k+ Medical Conditions' icon={faStethoscope} />
      <Result copy='50k+ Cities' icon={faCity} />
    </AnimatedContainer>
  )
}
