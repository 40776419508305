import Button from '@components/primitives/Button'
import Link from '@components/primitives/Link'
import { Text } from '@components/primitives/Text'
import { faMagnifyingGlass } from '@fortawesome/pro-regular-svg-icons/faMagnifyingGlass'
import Routes from '@lib/routes'
import { TESTIMONIALS } from './consts'
import TestimonialCarousel from './TestimonialCarousel'

export default function TestimonialSection() {
  return (
    <>
      <Text
        element='h2'
        styleName='h2'
        value='Why have other patients joined clinical trials?'
      />
      <TestimonialCarousel
        speed='fast'
        testimonials={TESTIMONIALS.slice(0, 9)}
      />
      <TestimonialCarousel
        speed='slow'
        testimonials={TESTIMONIALS.slice(9, 17)}
      />
      <div className='flex w-full justify-center'>
        <Link href={Routes.search}>
          <Button.Icon
            className='rounded-full'
            color='black'
            icon={faMagnifyingGlass}
            iconClassName='text-white mr-2'
          >
            <Text className='text-white' value='View trials' />
          </Button.Icon>
        </Link>
      </div>
    </>
  )
}
