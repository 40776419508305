export type ConditionCardData = {
  condition: string
  description?: string
  slug: string
  topic: ConditionTopic
}

export enum ConditionTopic {
  // Not alphabetical since we want them to appear in this order
  Psychiatry = 'Psychiatry',
  Cancer = 'Cancer',
  ChronicIllness = 'Chronic Illness',
  Neurology = 'Neurology',
  ImmuneSystem = 'Immune System',
  RespiratoryIllness = 'Respiratory Illness',
  Endocrinology = 'Endocrinology',
  RareDiseases = 'Rare Diseases',
  All = 'View All',
}

export const CONDITION_TOPICS: ConditionCardData[] = [
  {
    condition: 'Depression',
    slug: 'depression',
    topic: ConditionTopic.Psychiatry,
  },
  {
    condition: 'Anxiety',
    slug: 'anxiety',
    topic: ConditionTopic.Psychiatry,
  },
  {
    condition: 'Schizophrenia',
    slug: 'schizophrenia',
    topic: ConditionTopic.Psychiatry,
  },
  {
    condition: 'Bipolar Disorder',
    slug: 'bipolar-disorder',
    topic: ConditionTopic.Psychiatry,
  },
  {
    condition: 'ADHD',
    slug: 'adhd',
    topic: ConditionTopic.Psychiatry,
  },
  {
    condition: 'Borderline Personality',
    slug: 'borderline-personality',
    topic: ConditionTopic.Psychiatry,
  },
  {
    condition: 'Lung Cancer',
    slug: 'lung-cancer',
    topic: ConditionTopic.Cancer,
  },
  {
    condition: 'Prostate Cancer',
    slug: 'prostate-cancer',
    topic: ConditionTopic.Cancer,
  },
  {
    condition: 'Breast Cancer',
    slug: 'breast-cancer',
    topic: ConditionTopic.Cancer,
  },
  {
    condition: 'Pancreatic Cancer',
    slug: 'pancreatic-cancer',
    topic: ConditionTopic.Cancer,
  },
  {
    condition: 'Colorectal Cancer',
    slug: 'colorectal-cancer',
    topic: ConditionTopic.Cancer,
  },
  {
    condition: 'Multiple Myeloma',
    slug: 'multiple-myeloma',
    topic: ConditionTopic.Cancer,
  },
  {
    condition: 'Tinnitus',
    slug: 'tinnitus',
    topic: ConditionTopic.ChronicIllness,
  },
  {
    condition: 'Autism Spectrum Disorder',
    slug: 'autism',
    topic: ConditionTopic.ChronicIllness,
  },
  {
    condition: 'Fibromyalgia',
    slug: 'fibromyalgia',
    topic: ConditionTopic.ChronicIllness,
  },
  {
    condition: 'Chronic Pain',
    slug: 'chronic-pain',
    topic: ConditionTopic.ChronicIllness,
  },
  {
    condition: 'Endometriosis',
    slug: 'endometriosis',
    topic: ConditionTopic.ChronicIllness,
  },
  {
    condition: 'Insomnia',
    slug: 'insomnia',
    topic: ConditionTopic.ChronicIllness,
  },
  {
    condition: "Alzheimer's Disease",
    slug: 'alzheimers-disease',
    topic: ConditionTopic.Neurology,
  },
  {
    condition: 'Migraine',
    slug: 'migraine',
    topic: ConditionTopic.Neurology,
  },
  {
    condition: "Parkinson's Disease",
    slug: 'parkinsons-disease',
    topic: ConditionTopic.Neurology,
  },
  {
    condition: 'Amyotrophic Lateral Sclerosis',
    slug: 'amyotrophic-lateral-sclerosis',
    topic: ConditionTopic.Neurology,
  },
  {
    condition: 'Epilepsy',
    slug: 'epilepsy',
    topic: ConditionTopic.Neurology,
  },
  {
    condition: 'Spinal Cord Injury',
    slug: 'spinal-cord-injury',
    topic: ConditionTopic.Neurology,
  },
  {
    condition: 'Rheumatoid Arthritis',
    slug: 'rheumatoid-arthritis',
    topic: ConditionTopic.ImmuneSystem,
  },
  {
    condition: 'Lupus',
    slug: 'lupus',
    topic: ConditionTopic.ImmuneSystem,
  },
  {
    condition: 'Multiple Sclerosis',
    slug: 'multiple-sclerosis',
    topic: ConditionTopic.ImmuneSystem,
  },
  {
    condition: 'Psoriasis',
    slug: 'psoriasis',
    topic: ConditionTopic.ImmuneSystem,
  },
  {
    condition: 'HIV',
    slug: 'hiv',
    topic: ConditionTopic.ImmuneSystem,
  },
  {
    condition: 'Sjögren Syndrome',
    slug: 'sjogren-syndrome',
    topic: ConditionTopic.ImmuneSystem,
  },
  {
    condition: 'COPD',
    slug: 'copd',
    topic: ConditionTopic.RespiratoryIllness,
  },
  {
    condition: 'Asthma',
    slug: 'asthma',
    topic: ConditionTopic.RespiratoryIllness,
  },
  {
    condition: 'Sleep Apnea',
    slug: 'sleep-apnea',
    topic: ConditionTopic.RespiratoryIllness,
  },
  {
    condition: 'Iodiopathic Pulmonary Fibrosis',
    slug: 'idiopathic-pulmonary-fibrosis',
    topic: ConditionTopic.RespiratoryIllness,
  },
  {
    condition: 'Interstitial Lung Disease',
    slug: 'interstitial-lung-disease',
    topic: ConditionTopic.RespiratoryIllness,
  },
  {
    condition: 'Mesothelioma',
    slug: 'mesothelioma',
    topic: ConditionTopic.RespiratoryIllness,
  },
  {
    condition: 'Diabetes',
    slug: 'diabetes',
    topic: ConditionTopic.Endocrinology,
  },
  {
    condition: 'Weight Loss',
    slug: 'weight-loss',
    topic: ConditionTopic.Endocrinology,
  },
  {
    condition: 'Inferitility',
    slug: 'inferitility',
    topic: ConditionTopic.Endocrinology,
  },
  {
    condition: 'Hyper/hypothyroidism',
    slug: 'hyper-hypothyroidism',
    topic: ConditionTopic.Endocrinology,
  },
  {
    condition: "Cushing's Syndrome",
    slug: 'cushings-syndrome',
    topic: ConditionTopic.Endocrinology,
  },
  {
    condition: "Graves' Disease",
    slug: 'graves-disease',
    topic: ConditionTopic.Endocrinology,
  },
  {
    condition: 'Cystic Fibrosis',
    slug: 'cystic-fibrosis',
    topic: ConditionTopic.RareDiseases,
  },
  {
    condition: 'Retinitis Pigmentosa',
    slug: 'retinitis-pigmentosa',
    topic: ConditionTopic.RareDiseases,
  },
  {
    condition: 'Duchenne Muscular Dystrophy',
    slug: 'duchenne-muscular-dystrophy',
    topic: ConditionTopic.RareDiseases,
  },
  {
    condition: 'Ehlers-Danlos Syndrome',
    slug: 'ehlers-danlos-syndrome',
    topic: ConditionTopic.RareDiseases,
  },
  {
    condition: 'Sickle Cell Disease',
    slug: 'sickle-cell-disease',
    topic: ConditionTopic.RareDiseases,
  },
  {
    condition: 'Haemophilia',
    slug: 'haemophilia',
    topic: ConditionTopic.RareDiseases,
  },
  {
    condition: 'Guillain-Barré Syndrome',
    slug: 'guilain-barre-syndrome',
    topic: ConditionTopic.RareDiseases,
  },
]
