import AnimatedContainer from '@components/primitives/AnimatedContainer/index.client'
import { CloudflareImage } from '@components/primitives/Image/Cloudflare'
import { Text } from '@components/primitives/Text'
import animationPresets from '@lib/animation/presets'
import {
  axiosLogo,
  betaKitLogo,
  bizjournalLogo,
  fierceLogo,
  forbesLogo,
  statHealthLogo,
} from '@modules/images/pages'

export default function DiscoverySection() {
  const AnimatedLogo = ({
    delay,
    logo,
  }: {
    delay: number
    logo: JSX.Element
  }) => (
    <AnimatedContainer
      animationOptions={animationPresets.fadeIn({
        delay,
        duration: 400,
      })}
      className='mx-8 my-2 max-w-[150px] opacity-0'
    >
      {logo}
    </AnimatedContainer>
  )

  return (
    <div className='mb-12 mt-10 grid grid-cols-1 gap-6'>
      <AnimatedContainer
        animationOptions={animationPresets.fadeIn({
          delay: 1200,
          duration: 400,
        })}
        className='text-center opacity-0'
      >
        <Text
          className='text-neutral500'
          styleName='p-tiny'
          value='Featured In'
        />
      </AnimatedContainer>
      <div className='grid grid-cols-2 grid-rows-3 items-center justify-items-center lg:grid-cols-6 lg:grid-rows-1'>
        {AnimatedLogo({
          delay: 1800,
          logo: (
            <CloudflareImage
              alt='Axios Logo'
              fit='fill'
              height={384}
              imageId={axiosLogo}
              width={683}
            />
          ),
        })}
        {AnimatedLogo({
          delay: 2000,
          logo: (
            <CloudflareImage
              alt='BetaKit Logo'
              fit='fill'
              height={384}
              imageId={betaKitLogo}
              width={683}
            />
          ),
        })}
        {AnimatedLogo({
          delay: 2200,
          logo: (
            <CloudflareImage
              alt='BizJournals Logo'
              fit='fill'
              height={384}
              imageId={bizjournalLogo}
              width={683}
            />
          ),
        })}
        {AnimatedLogo({
          delay: 2400,
          logo: (
            <CloudflareImage
              alt='Fierce Logo'
              fit='fill'
              height={384}
              imageId={fierceLogo}
              width={683}
            />
          ),
        })}
        {AnimatedLogo({
          delay: 2600,
          logo: (
            <CloudflareImage
              alt='Forbes Logo'
              fit='fill'
              height={384}
              imageId={forbesLogo}
              width={683}
            />
          ),
        })}
        {AnimatedLogo({
          delay: 2800,
          logo: (
            <CloudflareImage
              alt='Stat Health Logo'
              fit='fill'
              height={384}
              imageId={statHealthLogo}
              width={683}
            />
          ),
        })}
      </div>
    </div>
  )
}
