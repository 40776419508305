import Button from '@components/primitives/Button'
import { CloudflareImage } from '@components/primitives/Image/Cloudflare'
import { Text } from '@components/primitives/Text'
import Routes from '@lib/routes'
import {
  trackEventWithFacebook,
  TrackingEvent,
  TrackingSource,
} from '@lib/tracking'
import { birthdayCandles } from '@modules/images/pages'
import styles from './styles.module.scss'

export default function ExplorePanel() {
  const trackCtaClick = () =>
    trackEventWithFacebook(TrackingEvent.VIEW_TRIALS_CLICKED, {
      source: TrackingSource.LOWER_PHOTO_CARD,
    })

  return (
    <div
      className={`${styles['dark-gradient']} mx-auto my-24 flex w-full flex-col items-center rounded-xl lg:grid lg:grid-cols-2`}
    >
      <div className='grid grid-cols-1 gap-4 p-16 lg:p-24 lg:text-left'>
        <Text
          className='text-white'
          styleName='h3'
          value='Explore Cutting-Edge Treatments at Top Hospitals'
        />
        <Text
          className='text-light'
          styleName='h5'
          value='Filter by stage of illness or course of treatment, then choose your ideal trial by comparing side effects and efficacy data.'
        />
        <Button.Link
          className='rounded-full'
          color='hope'
          href={Routes.nearMe}
          onClick={trackCtaClick}
          value='View Trials'
        />
      </div>
      <div className='col-span-1 hidden h-full lg:block'>
        <CloudflareImage
          alt='Explore trials'
          className='h-full rounded-r-xl object-cover'
          height={666}
          imageId={birthdayCandles}
          width={1000}
        />
      </div>
    </div>
  )
}
