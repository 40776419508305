export const card1 = 'a8a5bf62-aa15-4a11-bd55-657149a32900'
export const card2 = '8f0214fb-2576-4aed-938c-1fc7db9a8b00'
export const card3 = '56240ca5-56da-4cb8-696e-5f0e2f8cad00'
export const conditionSearch = '4259f2ca-d788-40a3-8db5-9bdc2748b300'
export const birthdayCandles = 'f7c1d12f-3c72-4354-bb72-0fdf0abd7600'

// Logos
export const axiosLogo = '4177a342-f5af-491f-7552-4a80fea31500'
export const fierceLogo = 'd05007fb-f8a6-49be-6f4f-f72f44043000'
export const mobiHealthNewsLogo = '61df89d7-cc99-4c6e-f3c5-eb7c696aec00'
export const bizjournalLogo = 'c0c83762-d5ac-4bc9-d8d2-eb1bbcbf1800'
export const forbesLogo = '02503560-fda7-443a-a455-5e6b87766f00'
export const betaKitLogo = '12e6d29b-fe05-4af8-784c-5490ca2e3100'
export const statHealthLogo = '06a49fb4-7fc2-4c25-289a-71063defb100'
export const harvardMedicalLogo = '7f25d719-437c-40e1-0aec-fc2e2dbe2500'
export const standfordMedicineLogo = '43aa4eb4-b0df-409e-2c67-caf194804e00'

export const previewImage1 = '8e9c39b0-4eb4-4bab-c86a-379617fe4400'
export const previewImage2 = 'b097298d-cf6d-4f3b-a9ce-0e7246705a00'
export const previewImage3 = '1a0574c0-2ad3-491e-7b29-6d5dfb6b0800'

// Testimonials
export const testimonial0 = 'a091aede-4bf9-4f25-369b-36e77616b400'
export const testimonial1 = '60460208-e27e-442c-3c9c-a88b1ebd0b00'
export const testimonial2 = '57d3356d-16bb-48c0-304b-6c7f35faed00'
export const testimonial3 = '62243982-48dd-4551-cf45-6d3df7dba800'
export const testimonial4 = 'f5a02dd7-5483-43d1-185f-367788b47a00'
export const testimonial5 = '63fd7f27-d706-4bbb-6b64-ad1c33444800'
export const testimonial6 = '5a2b8cec-7c0f-420d-ace2-dd18045fc200'
export const testimonial7 = 'a3e4aca4-5923-462f-53d5-2e25fbcbdd00'
export const testimonial8 = 'd064e40a-4ff0-47e4-0c21-75b72a641f00'
export const testimonial9 = '89ae046b-88f1-4dd3-463a-d694f5f45500'
export const testimonial10 = '0902e342-a628-4e04-ea84-56c19485ed00'
export const testimonial11 = '692ded53-8760-4d1d-65a9-c605f3bcf100'
export const testimonial12 = '25288330-0103-460a-9da7-744ae069a800'
export const testimonial13 = '3c415edf-4764-4b33-b229-e3aa06638500'
export const testimonial14 = 'ee857cf9-b58c-4391-5e11-0855ec4bae00'
export const testimonial15 = 'd890ae95-b085-4168-6ae5-43ec7729a200'
export const testimonial16 = '54a905c7-b077-479f-88d4-1d7be17c8900'
